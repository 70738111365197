import { useTranslation } from 'react-i18next';

export enum TRANSLATION_PATH {
  BASE = 'translation',
}

const useLanguageConstants = () => {
  const { t } = useTranslation();

  const languageOptions = [
    {
      label: t('English'),
      value: 'en',
    },
    { label: t('French'), value: 'fr' },
  ];

  return { languageOptions };
};

export default useLanguageConstants;
