import { ResultStatusType } from 'antd/es/result';

export interface ErrorDescriptions {
  message: string;
  statusCode: ResultStatusType;
  title: string;
}

export interface FormError {
  name: string[];
  errors: string[];
}

export interface ToastError {
  title: string;
  message: string;
  type: RESPONSE_TYPE;
}

export interface ServerConflictError {
  status: number;
  data: {
    type: string;
    affectedEntryType: string;
    title: string;
    message: string;
    affectedFields: string;
    affectedEntities: [
      {
        id: number;
        name: string;
      },
    ];
  }[];
}

export interface ServerError {
  status: number;
  data: {
    success: boolean;
    errors: FormError[];
  };
}

export enum RESPONSE_TYPE {
  SUCCESS = 'Success',
  ERROR = 'Error',
  WARNING = 'Warning',
}
