import Text from 'components/typography/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  description: string;
}

const MessageDescriptionComponent = ({ description }: Props) => {
  const { t } = useTranslation();
  const styles = {
    text: {
      fontFamily: 'area-normal, sans-serif',
    },
  };

  return <Text style={styles.text}>{t(description)}</Text>;
};

export default MessageDescriptionComponent;
