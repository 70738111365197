import { Navigate } from 'react-router-dom';
import { ROUTES_PUBLIC } from 'constants/routes.constants';
import React from 'react';
import { useAppSelector } from 'hooks/useStore';
import { STORE_SLICES } from 'constants/store.constants';
import { USER_ROLES } from 'features/Users/types/user.types';
import useRoles from 'hooks/useRoles';

interface Props {
  children: JSX.Element;
  roles?: USER_ROLES[];
}

const PrivateRoute = ({ children, roles = [] }: Props) => {
  const tokens = useAppSelector(state => state[STORE_SLICES.AUTH].tokens);

  const { getUserRole } = useRoles();
  const role = getUserRole();

  const isRoleHasAccess = !roles?.length || roles?.some(item => role === item);

  return tokens ? (
    isRoleHasAccess ? (
      children
    ) : (
      <Navigate to={`${ROUTES_PUBLIC.ERROR}/404`} replace />
    )
  ) : (
    <Navigate to={ROUTES_PUBLIC.LOGIN} replace />
  );
};

export default PrivateRoute;
