import { Middleware } from 'redux';
import { get } from 'lodash';

import { STORE_SLICES } from 'constants/store.constants';

const persistActions = [
  `${STORE_SLICES.AUTH}/setTokens`,
  `${STORE_SLICES.AUTH}/resetAuthState`,
  `${STORE_SLICES.AUTH}/setUserEmail`,
  `${STORE_SLICES.CLIENTS}/setActiveClient`,
  `${STORE_SLICES.USERS}/setUserId`,
] as const;

const localStorageMiddleware: Middleware = store => {
  return next => action => {
    const result = next(action) as any;
    if (persistActions.includes(result.type)) {
      const pathArray = result.type.split('/').slice(0, -1);
      const key = pathArray.join('.');
      const localStorageKey = pathArray.join('/');

      localStorage.setItem(
        localStorageKey,
        JSON.stringify(get(store.getState(), key))
      );
    }
    return result;
  };
};

export default localStorageMiddleware;
