import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem('language') || 'en',
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/translations/{{lng}}/{{ns}}.json`,
      expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
    },
  });

export default i18n;
