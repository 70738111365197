import React from 'react';
import 'theme/styles.css';
import AppRoutes from 'routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import store from 'store/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import themeConfig from 'theme/theme';
import RedirectProvider from 'provider/RedirectProvider';

const App = () => {
  const isWindows = () => {
    return navigator.userAgent.indexOf('Windows') !== -1;
  };

  if (isWindows()) {
    document.body.classList.add('windows-os');
  }

  return (
    <ConfigProvider theme={themeConfig}>
      <Provider store={store}>
        <BrowserRouter>
          <RedirectProvider>
            <AppRoutes />
          </RedirectProvider>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
