import { useAppSelector } from 'hooks/useStore';
import { STORE_SLICES } from 'constants/store.constants';
import { jwtDecode } from 'jwt-decode';
import { AccessToken } from 'features/Auth/types/auth.types';
import { USER_ROLES } from 'features/Users/types/user.types';

const useRoleCheck = () => {
  const getTokensFromStorage = () => {
    const auth = localStorage.getItem('auth');
    return auth ? JSON.parse(auth)?.tokens : undefined;
  };

  const decodeToken = (token?: string) => {
    return token ? jwtDecode<AccessToken>(token) : null;
  };

  const getUserRole = () => {
    const accessToken = useAppSelector(
      state => state[STORE_SLICES.AUTH].tokens?.accessToken
    );
    const decodedToken = decodeToken(accessToken);
    const { role: userRole } = decodedToken || {};

    return userRole;
  };

  const checkUserRole = (roles: USER_ROLES[]) => {
    const userRole = getUserRole();

    return roles.some(role => role === userRole);
  };

  return { getUserRole, checkUserRole, getTokensFromStorage, decodeToken };
};

export default useRoleCheck;
