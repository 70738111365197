import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_SLICES } from 'constants/store.constants';
import { FORMULA_ERRORS } from 'features/Allocations/constants/allocation.constants';
import {
  Allocation,
  ALLOCATION_DRAWER_TYPE,
  ALLOCATION_FORM_STATE,
} from 'features/Allocations/types/allocation.types';

export interface AllocationsState {
  locationCount: number;
  itemsTotal: number;
  isUnfilledField: boolean;
  requiredError: FORMULA_ERRORS | null;
  isQtyFilled: boolean;
  isEmptyRules: boolean;
  drawerState: ALLOCATION_FORM_STATE;
  allocationTemplate: Allocation | null;
  allocationTemplateId: number | null;
  allocationResults: Location[] | null;
  allocationDrawerType: ALLOCATION_DRAWER_TYPE;
  isSavingAsMasterAllocation: boolean;
  isTemplateEdited: boolean;
}

const sliceName = STORE_SLICES.ALLOCATIONS;

const initialState: AllocationsState = {
  locationCount: 0,
  itemsTotal: 0,
  isUnfilledField: false,
  isQtyFilled: false,
  isEmptyRules: false,
  requiredError: null,
  drawerState: ALLOCATION_FORM_STATE.DEFAULT,
  allocationTemplate: null,
  allocationTemplateId: null,
  allocationResults: null,
  allocationDrawerType: ALLOCATION_DRAWER_TYPE.MASTER_ALLOCATION,
  isSavingAsMasterAllocation: false,
  isTemplateEdited: false,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLocationCount: (state, { payload }: PayloadAction<number>) => {
      state.locationCount = payload;
    },
    setItemsTotal: (state, { payload }: PayloadAction<number>) => {
      state.itemsTotal = payload;
    },
    setIsUnfilledField: (state, { payload }: PayloadAction<boolean>) => {
      state.isUnfilledField = payload;
    },
    setIsQtyFilled: (state, { payload }: PayloadAction<boolean>) => {
      state.isQtyFilled = payload;
    },
    setIsEmptyRules: (state, { payload }: PayloadAction<boolean>) => {
      state.isEmptyRules = payload;
    },
    setRequiredError: (
      state,
      { payload }: PayloadAction<FORMULA_ERRORS | null>
    ) => {
      state.requiredError = payload;
    },
    setDrawerState: (
      state,
      { payload }: PayloadAction<ALLOCATION_FORM_STATE>
    ) => {
      state.drawerState = payload;
    },
    setAllocationTemplateState: (
      state,
      { payload }: PayloadAction<Allocation | null>
    ) => {
      state.allocationTemplate = payload;
    },
    setAllocationTemplateId: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.allocationTemplateId = payload;
    },
    setAllocationResults: (
      state,
      { payload }: PayloadAction<Location[] | null>
    ) => {
      state.allocationResults = payload;
    },
    setAllocationDrawerState: (
      state,
      { payload }: PayloadAction<ALLOCATION_DRAWER_TYPE>
    ) => {
      state.allocationDrawerType = payload;
    },
    setIsSavingAsMasterAllocation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavingAsMasterAllocation = payload;
    },
    setIsTemplateEdited: (state, { payload }: PayloadAction<boolean>) => {
      state.isTemplateEdited = payload;
    },
    resetAllocationState: () => initialState,
  },
});

export const {
  setLocationCount,
  setItemsTotal,
  setIsUnfilledField,
  setIsQtyFilled,
  setIsEmptyRules,
  setRequiredError,
  setDrawerState,
  setAllocationTemplateState,
  resetAllocationState,
  setAllocationTemplateId,
  setAllocationDrawerState,
  setIsSavingAsMasterAllocation,
  setAllocationResults,
  setIsTemplateEdited,
} = slice.actions;

export default slice.reducer;
