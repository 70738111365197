import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useToken } from 'theme/theme';

const MessageIconComponent = () => {
  const { token } = useToken();

  const styles = {
    icon: { width: '22px', height: '22px', color: token.colorError },
  };

  return <ExclamationCircleOutlined style={styles.icon} />;
};

export default MessageIconComponent;
