import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STORE_SLICES } from 'constants/store.constants';
import { UserStore } from 'features/Users/types/user.types';

const sliceName = STORE_SLICES.USERS;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as UserStore)
  : null;

const defaultState: UserStore = {};

const initialState: UserStore = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUserId: (state, { payload }: PayloadAction<string>) => {
      state.userId = payload;
    },
  },
});

export const { setUserId } = slice.actions;

export default slice.reducer;
