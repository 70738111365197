import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STORE_SLICES } from 'constants/store.constants';
import { ClientStore } from 'features/Clients/types/clients.types';
import { UserClients } from 'features/Users/types/user.types';

const sliceName = STORE_SLICES.CLIENTS;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as ClientStore)
  : null;

const defaultState = {};

const initialState: ClientStore = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setActiveClient: (state, { payload }: PayloadAction<UserClients>) => {
      state.activeClient = payload;
    },
  },
});

export const { setActiveClient } = slice.actions;

export default slice.reducer;
