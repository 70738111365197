import { configureStore } from '@reduxjs/toolkit';
import api from 'store/middlewares/api';
import { STORE_SLICES } from 'constants/store.constants';
import authReducer from 'features/Auth/store/slices/auth-slice';
import clientReducer from 'features/Clients/store/slices/clients-slice';
import utilsReducer from 'store/utils/slices/utils-slice';
import usersReducer from 'features/Users/store/users-slice';
import allocationsReducer from 'features/Allocations/store/slices/allocation-slice';
import localStorageMiddleware from 'store/middlewares/local-storage';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [STORE_SLICES.AUTH]: authReducer,
    [STORE_SLICES.CLIENTS]: clientReducer,
    [STORE_SLICES.UTILS]: utilsReducer,
    [STORE_SLICES.USERS]: usersReducer,
    [STORE_SLICES.ALLOCATIONS]: allocationsReducer,
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      api.middleware,
      localStorageMiddleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
