import { Spin } from 'antd';
import React, { PropsWithChildren } from 'react';
import { StylesProperties } from 'types/styles.types';

interface Props {
  height: string;
}

const PageSuspenseComponent = ({
  children,
  height,
}: PropsWithChildren<Props>) => {
  const styles: StylesProperties = {
    container: { height: height, overflow: 'auto' },
    spin: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <Spin style={styles.spin} size="large">
        {children}
      </Spin>
    </div>
  );
};

export default PageSuspenseComponent;
