export enum API_TAGS {
  CAMPAIGNS = 'Campaigns',
  CAMPAIGN = 'Campaign',
  DISTRIBUTIONS = 'Distributions',
  DISTRIBUTION = 'Distribution',
  DISTRIBUTION_REPORTS = 'DistributionReports',
  MASTER_ITEMS = 'MasterItems',
  ITEMS = 'Items',
  LOCATIONS = 'Locations',
  AUTH = 'Auth',
  USER = 'User',
  CLIENT = 'Client',
  TABLE = 'Table',
  ATTRIBUTE_ITEMS = 'AttributeItems',
  ATTRIBUTE_LOCATIONS = 'AttributeLocations',
  LOGS = 'Logs',
  MASTER_ALLOCATION = 'MasterAllocation',
  ALLOCATION = 'Allocation',
}

export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

enum BASE_URL {
  AUTH = 'account',
  USER = 'user',
  CLIENT = 'client',
  TABLE_CONFIG = 'table-column-config',
  ITEM_ATTRIBUTE = 'master-item-attribute',
  MASTER_ITEM = 'master-item',
  LOCATION_ATTRIBUTE = 'location-attribute',
  LOCATION = 'location',
  ARCHIVE = 'archive',
  COPY = 'copy',
  UNARCHIVE = 'unarchive',
  SYSTEM = 'system',
  LOGS = 'change-logs',
  CAMPAIGN = 'campaign',
  DISTRIBUTION = 'distribution',
  MASTER_ALLOCATION = 'masterAllocation',
  ALLOCATION = 'allocation',
  RESULTS = 'result',
}

enum AUTH_URL {
  LOGIN = 'login',
  REGISTER = 'register',
  LOGOUT = 'logout',
  FORGOT_PASSWORD = 'forgot-password',
  VERIFY = 'verify-otp-code',
  RESET = 'reset-password',
  REFRESH = 'refresh-token',
}

enum COMMON_URL {
  BULK = 'bulk',
  LIST = 'list',
  EXPORT = 'export',
}

enum CLIENTS_URL {
  USER_CLIENTS = 'user-clients',
  SET_ACTIVE_CLIENT = 'setLastSelectedClientByUser',
}

enum SYSTEM_URL {
  BUILD_NUMBER = 'buildnumber',
}

export enum LOGS_URL {
  LOGS = 'change-logs',
}

export enum CAMPAIGN_URL {
  ADD_MASTER_ITEMS = 'addMasterItems',
  ADD_ITEM = 'addNewItemToCampaign',
  COPY_CAMPAIGN_ITEM = 'copyCampaignItem',
  COPY_CAMPAIGN_ITEMS_LIST = 'copy-items',
  ITEMS = 'items',
  ITEM = 'item',
  RESULT = 'result',
}

export enum DISTRIBUTION_URL {
  CAMPAIGNS = 'campaigns',
  LIST = 'list',
  AVAILABLE = 'available',
  ADD = 'add',
  REMOVE = 'remove',
  ITEM_QTY_REPORT = 'item-qty-report',
  GENERATE = 'generate',
  KIT_CONFIG_REPORT = 'kitting-output-worksheet',
}

export enum ALLOCATION_URL {
  ALLOCATION = 'allocations',
  ASSIGN_ALLOCATION = 'assignAllocation',
  DELETE_ALLOCATION = 'deleteAllocation',
}

export const AUTH_API_PATH = {
  LOGIN: `${BASE_URL.AUTH}/${AUTH_URL.LOGIN}`,
  REGISTER: `${BASE_URL.AUTH}/${AUTH_URL.REGISTER}`,
  LOGOUT: `${BASE_URL.AUTH}/${AUTH_URL.LOGOUT}`,
  FORGOT_PASSWORD: `${BASE_URL.AUTH}/${AUTH_URL.FORGOT_PASSWORD}`,
  VERIFY: `${BASE_URL.AUTH}/${AUTH_URL.VERIFY}`,
  RESET: `${BASE_URL.AUTH}/${AUTH_URL.RESET}`,
  REFRESH: `${BASE_URL.AUTH}/${AUTH_URL.REFRESH}`,
};

export const USER_API_PATH = {
  USER: BASE_URL.USER,
  ARCHIVE: `${BASE_URL.USER}/${BASE_URL.ARCHIVE}`,
  UNARCHIVE: `${BASE_URL.USER}/${BASE_URL.UNARCHIVE}`,
};

export const CLIENTS_API_PATH = {
  CLIENT: BASE_URL.CLIENT,
  USER_CLIENTS: `${BASE_URL.CLIENT}/${CLIENTS_URL.USER_CLIENTS}`,
  SET_ACTIVE_CLIENT: CLIENTS_URL.SET_ACTIVE_CLIENT,
};

export const TABLE_CONFIG_PATH = {
  TABLE_CONFIG: BASE_URL.TABLE_CONFIG,
  UPDATE_TABLE_CONFIG: `${BASE_URL.TABLE_CONFIG}/${COMMON_URL.BULK}`,
};

export const ATTRIBUTES_API_PATH = {
  ITEM_ATTRIBUTE: BASE_URL.ITEM_ATTRIBUTE,
  ITEM_ATTRIBUTE_ARCHIVE: `${BASE_URL.ITEM_ATTRIBUTE}/${BASE_URL.ARCHIVE}`,
  LOCATION_ATTRIBUTE: BASE_URL.LOCATION_ATTRIBUTE,
  LOCATION_ATTRIBUTE_ARCHIVE: `${BASE_URL.LOCATION_ATTRIBUTE}/${BASE_URL.ARCHIVE}`,
};

export const MASTER_ITEM_API_PATH = {
  MASTER_ITEM: BASE_URL.MASTER_ITEM,
  LIST: `${BASE_URL.MASTER_ITEM}/${COMMON_URL.LIST}`,
  MASTER_ITEM_ARCHIVE: `${BASE_URL.MASTER_ITEM}/${BASE_URL.ARCHIVE}`,
  MASTER_ITEM_COPY: `${BASE_URL.MASTER_ITEM}/${BASE_URL.COPY}`,
  MASTER_ITEM_EXPORT: `${BASE_URL.MASTER_ITEM}/${COMMON_URL.EXPORT}`,
};
export const LOCATION_API_PATH = {
  LOCATION: BASE_URL.LOCATION,
  LIST: `${BASE_URL.LOCATION}/${COMMON_URL.LIST}`,
  LOCATION_ARCHIVE: `${BASE_URL.LOCATION}/${BASE_URL.ARCHIVE}`,
  LOCATION_COPY: `${BASE_URL.LOCATION}/${BASE_URL.COPY}`,
  LOCATION_EXPORT: `${BASE_URL.LOCATION}/${COMMON_URL.EXPORT}`,
};

export const MASTER_ALLOCATION_API_PATH = {
  MASTER_ALLOCATION: BASE_URL.MASTER_ALLOCATION,
  LIST: `${BASE_URL.MASTER_ALLOCATION}/${COMMON_URL.LIST}`,
  RESULTS: `${BASE_URL.MASTER_ALLOCATION}/${BASE_URL.RESULTS}`,
};

export const ALLOCATION_API_PATH = {
  ALLOCATION: BASE_URL.ALLOCATION,
};

export const SYSTEM_API_PATH = {
  BUILD_NUMBER: `${BASE_URL.SYSTEM}/${SYSTEM_URL.BUILD_NUMBER}`,
};

export const LOGS_API_PATH = {
  LOGS: BASE_URL.LOGS,
};

export const CAMPAIGN_API_PATH = {
  CAMPAIGN: BASE_URL.CAMPAIGN,
  LIST: `${BASE_URL.CAMPAIGN}/${COMMON_URL.LIST}`,
  ITEM: `${BASE_URL.CAMPAIGN}/${CAMPAIGN_URL.ITEM}`,
};

export const DISTRIBUTION_API_PATH = {
  DISTRIBUTION: BASE_URL.DISTRIBUTION,
  LIST: `${BASE_URL.DISTRIBUTION}/${COMMON_URL.LIST}`,
  AVAILABLE_CAMPAIGNS: `${DISTRIBUTION_URL.CAMPAIGNS}/${DISTRIBUTION_URL.AVAILABLE}/${COMMON_URL.LIST}`,
};
