import React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { resetUtilsState } from 'store/utils/slices/utils-slice';
import { STORE_SLICES } from 'constants/store.constants';

const RedirectProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const redirectUrl = useAppSelector(
    state => state[STORE_SLICES.UTILS].redirectUrl
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
      dispatch(resetUtilsState());
    }
  }, [redirectUrl]);

  return <>{children}</>;
};

export default RedirectProvider;
