import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STORE_SLICES } from 'constants/store.constants';
import { TABS } from 'constants/page.constants';

export interface UtilsState {
  redirectUrl: string | null;
  isClientMenuOpen: boolean;
  recordCount: number;
  tabsCount: Record<TABS, number> | null;
}

const sliceName = STORE_SLICES.UTILS;

const initialState: UtilsState = {
  redirectUrl: null,
  isClientMenuOpen: false,
  recordCount: 0,
  tabsCount: null,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setRedirectUrl: (state, { payload }: PayloadAction<string>) => {
      state.redirectUrl = payload;
    },
    setIsClientMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isClientMenuOpen = payload;
    },
    setRecordCount: (state, { payload }: PayloadAction<number>) => {
      state.recordCount = payload;
    },
    setTabsCount: (
      state,
      { payload }: PayloadAction<{ count: number; type: TABS }>
    ) => {
      state.tabsCount = {
        ...state.tabsCount,
        [payload.type]: payload.count,
      } as Record<TABS, number>;
    },
    resetUtilsState: () => initialState,
  },
});

export const {
  setRedirectUrl,
  setIsClientMenuOpen,
  setRecordCount,
  resetUtilsState,
  setTabsCount,
} = slice.actions;

export default slice.reducer;
