import React from 'react';
import Text from 'components/typography/Text';
import { StylesProperties } from 'types/styles.types';
import { useTranslation } from 'react-i18next';
import { useToken } from 'theme/theme';

interface Props {
  title: string;
}

const MessageTitleComponent = ({ title }: Props) => {
  const { t } = useTranslation();
  const { token } = useToken();

  const style: StylesProperties = {
    title: {
      display: 'block',
      fontWeight: token.fontWeightStrong,
      fontFamily: 'area-normal, sans-serif',
    },
  };

  return <Text style={style.title}>{t(title)}</Text>;
};

export default MessageTitleComponent;
