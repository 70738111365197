import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STORE_SLICES } from 'constants/store.constants';
import { Tokens } from 'features/Auth/types/auth.types';

export interface AuthState {
  tokens: Tokens | null;
  userEmail?: string;
  code?: string;
}

const sliceName = STORE_SLICES.AUTH;
const savedState = localStorage.getItem(sliceName);

const parsedSavedState = savedState
  ? (JSON.parse(savedState) as AuthState)
  : null;

const defaultState: AuthState = {
  tokens: null,
};

const initialState: AuthState = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setTokens: (state, { payload }: PayloadAction<Tokens | null>) => {
      state.tokens = payload;
    },
    setUserEmail: (state, { payload }: PayloadAction<string>) => {
      state.userEmail = payload;
    },
    setAuthCode: (state, { payload }: PayloadAction<string>) => {
      state.code = payload;
    },
    resetAuthState: state => ({
      ...state,
      userEmail: undefined,
      code: undefined,
    }),
  },
});

export const { setTokens, resetAuthState, setUserEmail, setAuthCode } =
  slice.actions;

export default slice.reducer;
