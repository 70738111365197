import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ROUTES_PUBLIC, ROUTE_PATH } from 'constants/routes.constants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_PATH } from 'constants/translations.costants';
import PrivateRoute from 'components/private-route/PrivateRoute';
import PageSuspenseComponent from 'components/layout/PageSuspenseComponent';
import { RouteConfig } from 'types/routes.types';

const AuthLayoutComponent = React.lazy(
  () => import('features/Auth/components/layout/AuthLayoutComponent')
);
const AppLayoutComponent = React.lazy(
  () => import('components/layout/AppLayoutComponent')
);

const APP_ROUTES_CONFIG: RouteConfig[] = [
  {
    path: ROUTE_PATH.BASE,
    element: <Navigate to={ROUTE_PATH.HOME} />,
  },
  {
    path: `${ROUTES_PUBLIC.BASE}/*`,
    element: <AuthLayoutComponent />,
  },
  {
    path: '*',
    element: (
      <PrivateRoute>
        <AppLayoutComponent />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    element: <Navigate to={ROUTES_PUBLIC.LOGIN} replace />,
  },
];

const AppRoutes = () => {
  useTranslation([TRANSLATION_PATH.BASE]);

  return (
    <Suspense fallback={<PageSuspenseComponent height="100vh" />}>
      <Routes>
        {APP_ROUTES_CONFIG.map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
