export enum STORE_SLICES {
  AUTH = 'auth',
  DISTRIBUTIONS = 'distributions',
  CAMPAIGNS = 'campaigns',
  ITEMS = 'items',
  LOCATIONS = 'locations',
  CLIENTS = 'clients',
  UTILS = 'utils',
  USERS = 'users',
  ALLOCATIONS = 'allocations',
}
